<template>
  <div v-if="existe">
    <div class="wrapper landing-page">
      <div class="page-header page-header">
        <div class="page-header-image grayBG" :style="headerImagem()"></div>
        <div id="form" class="container">
          <div class="row fullHeight flexCenter">
            <div class="col-md-5 text-left">
              <div class="row titleHeader">
                <p class="label">
                  <b>{{ pagina[0].custom_fields.location }}</b>
                </p>
              </div>
              <div class="row titleHeader">
                <h1>{{ pagina[0].position_name }}</h1>
              </div>
              <div class="row backCenter">
                <button
                  @click="scrollToElement('segundaSecao')"
                  type="button"
                  class="btn btn-round btn-eugenia"
                >
                  KNOW MORE
                </button>
              </div>
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-6">
              <card
                class="p-5"
                :class="loading ? 'loading-overlay  border-radius' : 'glowForm'"
              >
                <div class="card-header text-green">
                  <h3>Apply now</h3>
                </div>
                <form>
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Full Name"
                      value=""
                      v-model="candidato.full_name"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="text"
                      id="mobile_code"
                      class="form-control"
                      placeholder="Phone Number"
                      name="name"
                      v-model="candidato.phone_number"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Email"
                      name="name"
                      v-model="candidato.email"
                    />
                  </div>
                  <div class="loading-overlay" v-if="loading">
                    <div class="loading-animation">
                      <img src="/img/lp/loading.gif" alt="Loading Animation" />
                    </div>
                  </div>
                  <input
                    class="form-control"
                    @change="uploadFile()"
                    type="file"
                    name="fileToUpload"
                    id="fileToUpload"
                  />
                  <div class="mt-3">
                    <n-checkbox
                      class="text-black text-left"
                      v-model="candidato.termos"
                    >
                      I've read and accept the
                      <span class="hoverTerms" @click="abrirTermos"
                        >Terms and Conditions</span
                      >
                    </n-checkbox>
                  </div>
                  <button
                    @click="apply()"
                    type="button"
                    class="btn btn-round btn-eugenia mt-5"
                  >
                    APPLY
                  </button>
                  <p class="errorForm" v-if="erro_formulario">
                    Please fill all fields.
                  </p>
                </form>
              </card>
            </div>
          </div>
        </div>
      </div>

      <div class="section section-about-us grayBG" id="segundaSecao">
        <div class="container">
          <div class="row">
            <div class="col-md-5 text-left">
              <div class="row">
                <div class="row">
                  <div class="col">
                    <h4 class="text-green"><b>JOB POSITION</b></h4>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <h1><strong>What we're looking for</strong></h1>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <button
                      @click="scrollToElement('form')"
                      type="button"
                      class="btn btn-round btn-eugenia"
                    >
                      APPLY NOW
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-3 text-left">
              <div class="row">
                <div class="col">
                  <h4><b>Type of position</b></h4>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <ul class="LN">
                    <li
                      v-for="(offer, i) in pagina[0].custom_fields
                        .typeofposition"
                      :key="i"
                    >
                      {{ offer }}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <h4><b>Sector</b></h4>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <ul class="LN">
                    <li
                      v-for="(sector, i) in pagina[0].custom_fields.sector"
                      :key="i"
                    >
                      {{ sector }}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <h4><b>Key languages</b></h4>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <ul class="LN">
                    <li
                      v-for="(language, i) in pagina[0].custom_fields
                        .keylanguages"
                      :key="i"
                    >
                      {{ language }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-3 text-left">
              <div class="row">
                <div class="col">
                  <h4><b>About our client</b></h4>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <ul class="LN">
                    <li>
                      {{
                        removeHTMLTags(pagina[0].custom_fields.aboutourclient)
                      }}
                    </li>
                    <!-- <li>
                      {{
                        decodedString(pagina[0].custom_fields.aboutourclient)
                      }}
                    </li> -->
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section section-about-us">
        <div class="container">
          <div class="row">
            <div class="col-md-5 text-left pbm-5">
              <img :src="offerImagem()" />
            </div>
            <div class="col-md-1 text-left"></div>
            <div class="col-md-6 text-left">
              <div class="row">
                <div class="col">
                  <h2><b>The offer</b></h2>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <ul class="pl-3">
                    <li
                      v-for="(offer, i) in pagina[0].custom_fields.theoffer_b"
                      :key="i"
                    >
                      {{ offer }}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="row mt-5">
                <div class="col">
                  <h2><b>Requirements</b></h2>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <ul class="pl-3">
                    <li
                      v-for="(requirement, i) in pagina[0].custom_fields
                        .requirements_b"
                      :key="i"
                    >
                      {{ requirement }}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="row mt-5 pbm-5">
                <button
                  @click="scrollToElement('form')"
                  type="button"
                  class="btn btn-round btn-eugenia"
                >
                  APPLY TO POSITION
                </button>
              </div>
            </div>
          </div>
          <div class="separator separator-primary" />
        </div>
      </div>
      <div class="about-description text-center grayBG">
        <div class="features-3">
          <div class="container">
            <div class="row">
              <div class="col-md-8 mr-auto ml-auto">
                <h2 class="title">Benefits</h2>
              </div>
            </div>
            <div class="row">
              <div class="col" v-if="pagina[0].custom_fields.icon">
                <div class="info info-hover">
                  <div
                    class="icon icon-success"
                    v-if="pagina[0].custom_fields.icon != ''"
                    v-html="pagina[0].custom_fields.icon"
                  ></div>
                  <p
                    class="info-title"
                    v-if="pagina[0].custom_fields.icontext != ''"
                  >
                    {{ pagina[0].custom_fields.icontext }}
                  </p>
                </div>
              </div>
              <div class="col" v-if="pagina[0].custom_fields.icon_b">
                <div class="info info-hover">
                  <div
                    class="icon icon-success"
                    v-if="pagina[0].custom_fields.icon_b != ''"
                    v-html="pagina[0].custom_fields.icon_b"
                  ></div>
                  <p
                    class="info-title"
                    v-if="pagina[0].custom_fields.icontext_b != ''"
                  >
                    {{ pagina[0].custom_fields.icontext_b }}
                  </p>
                </div>
              </div>
              <div class="col" v-if="pagina[0].custom_fields.icon_c">
                <div class="info info-hover">
                  <div
                    class="icon icon-success"
                    v-if="pagina[0].custom_fields.icon_c != ''"
                    v-html="pagina[0].custom_fields.icon_c"
                  ></div>
                  <p
                    class="info-title"
                    v-if="pagina[0].custom_fields.icontext_c != ''"
                  >
                    {{ pagina[0].custom_fields.icontext_c }}
                  </p>
                </div>
              </div>
              <div class="col" v-if="pagina[0].custom_fields.icon_d">
                <div class="info info-hover">
                  <div
                    class="icon icon-success"
                    v-if="pagina[0].custom_fields.icon_d != ''"
                    v-html="pagina[0].custom_fields.icon_d"
                  ></div>
                  <p
                    class="info-title"
                    v-if="pagina[0].custom_fields.icontext_d != ''"
                  >
                    {{ pagina[0].custom_fields.icontext_d }}
                  </p>
                </div>
              </div>
              <div class="col" v-if="pagina[0].custom_fields.icon_e">
                <div class="info info-hover">
                  <div
                    class="icon icon-success"
                    v-if="pagina[0].custom_fields.icon_e != ''"
                    v-html="pagina[0].custom_fields.icon_e"
                  ></div>
                  <p
                    class="info-title"
                    v-if="pagina[0].custom_fields.icontext_e != ''"
                  >
                    {{ pagina[0].custom_fields.icontext_e }}
                  </p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col text-center pbm-5">
                <button
                  @click="scrollToElement('form')"
                  type="button"
                  class="btn btn-round btn-eugenia"
                >
                  APPLY TO POSITION
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section about-description text-center">
        <div class="container">
          <div class="row">
            <div class="col-md-6 text-left">
              <div class="row">
                <div class="col">
                  <h2><b>The Process</b></h2>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <ol class="pl-3 process">
                    <li>
                      <b>Submit your application</b> and don't forget to send an
                      updated CV
                    </li>
                    <li><b>Get a call</b> by our Recruitment Specialist</li>
                    <li>Start your <b>interview process</b></li>
                    <li>Get <b>selected</b></li>
                    <li class="text-green"><b>Start your journey!</b></li>
                  </ol>
                </div>
              </div>
              <div class="row mt-5 pbm-5">
                <button
                  @click="scrollToElement('form')"
                  type="button"
                  class="btn btn-round btn-eugenia"
                >
                  APPLY TO POSITION
                </button>
              </div>
            </div>
            <div class="col-md-1 text-left"></div>
            <div class="col-md-5 text-left">
              <img :src="processImagem()" />
            </div>
          </div>
          <div class="separator separator-primary" />
        </div>
      </div>
      <div
        class="about-description text-center lastSection"
        style="
          background-image: url('/img/lp/img_bg.png');
          background-repeat: no-repeat;
          background-size: cover;
        "
      >
        <div class="features-3">
          <div class="container">
            <div class="row">
              <div class="col-md-8 mr-auto ml-auto">
                <h2 class="title text-white">Eugenia Talent</h2>
              </div>
            </div>
            <div class="row numbers">
              <div class="col">
                <animated-number :value="98"></animated-number>
                <p class="text-white"><strong>Talent Nationalities</strong></p>
              </div>
              <div class="col">
                <animated-number :value="61"></animated-number>
                <p class="text-white"><strong>Employers</strong></p>
              </div>
              <div class="col">
                <animated-number :value="5"></animated-number>
                <p class="text-white"><strong>Countries</strong></p>
              </div>
              <div class="col">
                <animated-number :value="12"></animated-number>
                <p class="text-white"><strong>Languages Spoken</strong></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="page-header page-header" style="background: #fff">
      <div class="container">
        <div class="content-center text-green">
          <h2 style="color: #000">
            😥 I am sorry, the page you are looking for does not exist.
          </h2>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {
    Card,
    Button,
    FormGroupInput,
    Tabs,
    TabPane,
    Checkbox,
    AnimatedNumber,
  } from "@/components";
  // import {Button, Checkbox, Radio, FormGroupInput, DropDown, Slider, Switch} from '@/components';
  import api from "@/api";
  export default {
    name: "LandingPage",
    bodyClass: "landing-page",
    components: {
      Card,
      Tabs,
      TabPane,
      [Button.name]: Button,
      [Checkbox.name]: Checkbox,
      [FormGroupInput.name]: FormGroupInput,
      AnimatedNumber,
    },
    data() {
      return {
        erro_formulario: false,
        form: {
          firstName: "",
          email: "",
          message: "",
        },
        candidato: {
          full_name: "",
          email: "",
          phone_number: "",
          termos: false,
          cv: "",
        },
        slug: "",
        cv: "",
        pagina: [],
        typeOffer: [],
        existe: false,
        loading: false,
      };
    },
    methods: {
      //decode:
      headerImagem() {
        console.log(this.pagina[0].custom_fields.headerimage);
        if (
          this.pagina[0].custom_fields.headerimage == "" ||
          !this.pagina[0].custom_fields.headerimage
        ) {
          return "background-image: url('img/lp/bannerLP.png')";
        }
        return (
          "background-image: url('" +
          this.pagina[0].custom_fields.headerimage +
          "')"
        );
      },
      abrirTermos() {
        var url = window.location.origin + "/docs/terms_and_conditions.pdf";
        window.open(url, "_blank");
      },
      offerImagem() {
        if (
          this.pagina[0].custom_fields.offerimage == "" ||
          !this.pagina[0].custom_fields.offerimage
        ) {
          return "/img/lp/first_sec_img.png";
        }
        return this.pagina[0].custom_fields.offerimage;
      },
      processImagem() {
        if (
          this.pagina[0].custom_fields.processimage == "" ||
          !this.pagina[0].custom_fields.processimage
        ) {
          return "/img/lp/imagem_sec_2.png";
        }
        return this.pagina[0].custom_fields.processimage;
      },
      removeHTMLTags(str) {
        return str.replace(/<[^>]*>/g, "");
      },
      decodedString(str) {
        var temp = decodeURIComponent(
          str.replace(/u(\w{4})/gi, function (match, hex) {
            return String.fromCharCode(parseInt(hex, 16));
          })
        );
        return temp;
      },
      async uploadFile() {
        var _this = this;
        var ficheiro = document.getElementById("fileToUpload").files[0];
        var formdata = new FormData();
        formdata.append("fileToUpload", ficheiro);

        var requestOptions = {
          method: "POST",
          body: formdata,
          redirect: "follow",
        };

        fetch(
          "https://yourcode-staging.com/scripts_cronjob/docs_eugenia/index.php",
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            if (result.caminho) {
              _this.cv = result.caminho;
            }
          })
          .catch((error) => console.log("error", error));
      },
      scrollToElement(elemento) {
        const element = document.getElementById(elemento);
        element.scrollIntoView({ behavior: "smooth" });
      },
      async apply() {
        if (
          this.candidato.phone_number == "" ||
          this.candidato.full_name == "" ||
          this.cv == "" ||
          !this.candidato.termos
        ) {
          this.erro_formulario = true;
          return;
        }
        var url = window.location.href;
        const searchParams = new URLSearchParams(window.location.search);
        var utm_source = searchParams.get("utm_source");
        var utm_medium = searchParams.get("utm_medium");
        var utm_campaign = searchParams.get("utm_campaign");
        var ref = searchParams.get("ref");
        if (!utm_source) {
          utm_source = "ORG";
        }
        if (!utm_medium) {
          utm_medium = "ORG";
        }
        if (!utm_campaign) {
          utm_campaign = "ORG";
        }
        if (!ref) {
          ref = "NA";
        }

        this.erro_formulario = false;
        this.loading = true;
        var phone_number =
          document.getElementsByClassName("iti__selected-dial-code").length > 0
            ? document.getElementsByClassName("iti__selected-dial-code")[0]
                .innerHTML + this.candidato.phone_number
            : this.candidato.phone_number;
        var json = {
          full_name: this.candidato.full_name,
          phone_number: phone_number,
          email: this.candidato.email,
          utm_source: utm_source,
          utm_medium: utm_medium,
          cv:
            this.cv != ""
              ? "https://yourcode-staging.com/scripts_cronjob/docs_eugenia/" +
                this.cv
              : "",
          job_id: this.pagina[0].id,
        };
        let res = await api.apply(json);
        if (res) {
          var jsonLogCV = {
            candidate: JSON.parse(res).candidate,
            resume: json.cv,
            full_name: this.candidato.full_name,
            phone_number: phone_number,
            email: this.candidato.email,
            job_id: this.pagina[0].id,
            url: url,
            utm_source: utm_source,
            utm_medium: utm_medium,
            utm_campaign: utm_campaign,
            ref: ref,
          };

          await api.logsCV(jsonLogCV);
          this.$router.push("/thank-you?ref=" + this.slug + "");
        }
      },
      removeHtmlTags(string) {
        // Remove HTML tags from string
        const regex = /<[^>]*>/g;
        const cleanString = string.replace(regex, "");

        // Split string by semicolons and store each element in an array
        const array = cleanString.split(";").map((item) => item.trim());

        return array;
      },
      async inicio() {
        // AINDA PARA ARRANJAR - UTMS NÃO CONTEMPLADOS
        var url = window.location.href;
        var regex = /\/job-offers\/([^/?]+)/;
        var match = url.match(regex);
        var slug = match && match[1];
        this.slug = slug;

        let json = {
          slug: slug,
        };
        let res = await api.buscarDadosPagina(json);
        let resultado = Object.values(res)[0];
        if (resultado && slug && slug != "") {
          let pagina = JSON.parse(resultado.pagina);
          this.typeOffer = pagina[0].custom_fields.typeofposition;
          this.pagina = pagina;
          this.existe = true;

          setTimeout(() => {
            jQuery("#mobile_code").intlTelInput({
              initialCountry: "pt",
              separateDialCode: true,
              //utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/11.0.4/js/utils.js",
            });
          }, 1000);
          this.pagina[0].custom_fields.theoffer_b = this.removeHtmlTags(
            this.pagina[0].custom_fields.theoffer_b
          );
          this.pagina[0].custom_fields.requirements_b = this.removeHtmlTags(
            this.pagina[0].custom_fields.requirements_b
          );
          console.log(this.pagina);
          console.log(this.removeHtmlTags(this.pagina[0].custom_fields.teste));
        } else {
          this.existe = false;
        }
      },
    },
    mounted() {
      this.inicio();
    },
  };
</script>
<style>
  .flexAlign {
    display: flex;
    align-items: center;
  }
  .fullHeight {
    height: 100%;
  }
  .flexCenter {
    display: flex;
    align-items: center;
    padding: 25px;
  }
  /* .lastSection {
    background-image: url("img/lp/img_bg.png");
  } */
</style>
